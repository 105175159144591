// Generated by Framer (4c8d0fb)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/ZBFLE7rDN";

const cycleOrder = ["zA4nXMiWT", "KJu6UBsun", "CQ1_XpR4D", "FQrMpINbj"];

const serializationHash = "framer-c21lB"

const variantClassNames = {CQ1_XpR4D: "framer-v-19hg851", FQrMpINbj: "framer-v-9fuh9f", KJu6UBsun: "framer-v-ywrl83", zA4nXMiWT: "framer-v-vkbs4n"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const toString = (value) => {
    return typeof value === "string" ? value : String(value);
};


const transformTemplate2 = (_, t) => `translateY(-50%) ${t}`

const transformTemplate3 = (_, t) => `translateX(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Desktop: "zA4nXMiWT", Laptop: "KJu6UBsun", Phone: "FQrMpINbj", Tablet: "CQ1_XpR4D"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "zA4nXMiWT"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "zA4nXMiWT", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-vkbs4n", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"zA4nXMiWT"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-7ea6b336-6e98-44f5-8954-63f32d2e302f, rgb(233, 238, 251))", borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, ...style}} {...addPropertyOverrides({CQ1_XpR4D: {"data-framer-name": "Tablet"}, FQrMpINbj: {"data-framer-name": "Phone"}, KJu6UBsun: {"data-framer-name": "Laptop"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.h1 className={"framer-styles-preset-76qyt"} data-styles-preset={"ZBFLE7rDN"} style={{"--framer-text-alignment": "center"}}>Hier erscheint in Kürze eine Übersicht unserer Aussteller.</motion.h1></React.Fragment>} className={"framer-1tdjd6a"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"bb7uwwPY1"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={toString("Hier erscheint in Kürze eine Übersicht unserer Aussteller.")} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({CQ1_XpR4D: {transformTemplate: transformTemplate3}, KJu6UBsun: {transformTemplate: transformTemplate2}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-c21lB.framer-trljfl, .framer-c21lB .framer-trljfl { display: block; }", ".framer-c21lB.framer-vkbs4n { height: 200px; overflow: hidden; position: relative; width: 1296px; will-change: var(--framer-will-change-override, transform); }", ".framer-c21lB .framer-1tdjd6a { flex: none; height: auto; left: 50%; position: absolute; top: 50%; white-space: pre-wrap; width: 1296px; word-break: break-word; word-wrap: break-word; }", ".framer-c21lB.framer-v-ywrl83.framer-vkbs4n { width: 1080px; }", ".framer-c21lB.framer-v-ywrl83 .framer-1tdjd6a { left: 0px; right: 0px; width: unset; }", ".framer-c21lB.framer-v-19hg851.framer-vkbs4n { width: 729px; }", ".framer-c21lB.framer-v-19hg851 .framer-1tdjd6a { bottom: 0px; left: 51%; top: unset; width: 200px; }", ".framer-c21lB.framer-v-9fuh9f.framer-vkbs4n { width: 351px; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 1296
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"KJu6UBsun":{"layout":["fixed","fixed"]},"CQ1_XpR4D":{"layout":["fixed","fixed"]},"FQrMpINbj":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameroYkDJh_Qu: React.ComponentType<Props> = withCSS(Component, css, "framer-c21lB") as typeof Component;
export default FrameroYkDJh_Qu;

FrameroYkDJh_Qu.displayName = "AustellerTabelle";

FrameroYkDJh_Qu.defaultProps = {height: 200, width: 1296};

addPropertyControls(FrameroYkDJh_Qu, {variant: {options: ["zA4nXMiWT", "KJu6UBsun", "CQ1_XpR4D", "FQrMpINbj"], optionTitles: ["Desktop", "Laptop", "Tablet", "Phone"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FrameroYkDJh_Qu, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})